<template>
    <div>
        <statistics-bar-chart-template scale="daily" type="trials">
            <template #title>{{ $t('trials.daily') }}</template>
        </statistics-bar-chart-template>

        <statistics-bar-chart-template scale="weekly" type="trials">
            <template #title>{{ $t('trials.weekly') }}</template>
        </statistics-bar-chart-template>

        <statistics-bar-chart-template scale="monthly" type="trials">
            <template #title>{{ $t('trials.monthly') }}</template>
        </statistics-bar-chart-template>
    </div>
</template>

<script>
import StatisticsBarChartTemplate from '../statistics-components/statistics-dynamic/StatisticsBarChartTemplate.vue';

export default {
    components: { StatisticsBarChartTemplate },
};
</script>

<style lang="scss" scoped></style>
